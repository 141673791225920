import React, { useState } from "react";
import { useIntl } from "react-intl";
import { Button, Col, CustomInput, FormGroup, Input } from "reactstrap";
import { useService } from "../context/ServiceContext";
import ListerTableSelectionModal from "./Modals/ListerTableSelectionModal";

function DependentCurveTestForm({ test, updateTest }) {
    const { motorLister, curveLister } = useService();
    const intl = useIntl();
    const [motorModalOpen, setMotorModalOpen] = useState(false);
    const [curveModalOpen, setCurveModalOpen] = useState(false);
    const toggleMotorModal = () => setMotorModalOpen(!motorModalOpen);
    const toggleCurveModal = () => setCurveModalOpen(!curveModalOpen);
    const dependent = test.reasonForChange;
  
    function handleMotorSelection(selection) {
      if (selection) {
        updateTest(test, "reasonForChange", { ...dependent, motor: selection.id });
      }
      toggleMotorModal();
    }

    function handleCurveSelection(selection) {
      if (selection) {
        updateTest(test, "reasonForChange", { ...dependent, curve: selection.id });
      }

      toggleCurveModal();
    }
  
    function updateReason(reason) {
      if (dependent.reason === "curve" && reason !== "curve") {
        updateTest(test, "reasonForChange", { 
          ...dependent, 
          subreasons: dependent.subreasons.includes(reason) ? 
            dependent.subreasons.filter(r => r !== reason) : [...dependent.subreasons, reason]
        });
      } else {
        updateTest(test, "reasonForChange", { ...dependent, reason: dependent.reason === reason ? null : reason, subreasons: [] });
      }
    }
  
    function updateChangeValue(prop, value) {
      updateTest(test, "reasonForChange", { ...dependent, [prop]: value });
    }

    function reasonGivenIs(reason) {
      return dependent.reason === reason || dependent.subreasons?.includes(reason);
    }
  
    function isDisabled(prop, ignoreNull) {
      return (ignoreNull || dependent.reason) && (dependent.reason !== "curve" && dependent.reason !== prop);
    }
  
    return (
      <>
        <h3>Reason for change</h3>
        <FormGroup row className="mb-1">
          <Col className="d-flex">
            <div className="form-label-group flex-grow-1 mr-2">
              <Input
                type="select"
                className="flex-grow-1"
                id={`rfc-connection-${test.id}`}
                placeholder="Connection"
                disabled={!reasonGivenIs("connection")}
                onChange={(e) => updateChangeValue("connection", e.target.value)}
              >
                <option value="">None</option>
                <option value="TRG">Delta</option>
                <option value="STAR">Star</option>
              </Input>
              <label htmlFor={`rfc-connection-${test.id}`}>Connection</label>
            </div>
  
            <CustomInput
              type="switch"
              id={`rfc-toggle-connection-${test.id}`}
              disabled={isDisabled("connection")}
              checked={reasonGivenIs("connection")}
              onChange={e => updateReason("connection")}
            />
          </Col>
        </FormGroup>
        <FormGroup row className="mb-1">
          <Col className="d-flex">
            <div className="form-label-group flex-grow-1 mr-2">
              <Input
                type="number"
                className="flex-grow-1"
                disabled={!reasonGivenIs("subFrequency")}
                value={dependent?.subFrequency}
                onChange={e => updateChangeValue("subFrequency", e.target.value)}
                placeholder="Sub. Freq."
                id={`rfc-subfreq-${test.id}`}
              />
              <label htmlFor={`rfc-subfreq-${test.id}`}>Sub. Freq.</label>
            </div>
            <CustomInput
              type="switch"
              id={`rfc-toggle-sub-freq-${test.id}`}
              disabled={isDisabled("subFrequency")}
              checked={reasonGivenIs("subFrequency")}
              onChange={e => updateReason("subFrequency")}
            />
          </Col>
        </FormGroup>
        <FormGroup row className="mb-1">
          <Col className="d-flex">
            <div className="form-label-group flex-grow-1 mr-2">
              <Input
                type="number"
                className="flex-grow-1"
                disabled={!reasonGivenIs("voltage")}
                value={dependent?.voltage}
                onChange={e => updateChangeValue("voltage", e.target.value)}
                placeholder="Volts"
                id={`rfc-volts-${test.id}`}
              />
              <label htmlFor={`rfc-volts-${test.id}`}>Volts</label>
            </div>
            <CustomInput
              type="switch"
              id={`rfc-toggle-volts-${test.id}`}
              disabled={isDisabled("voltage")}
              checked={reasonGivenIs("voltage")}
              onChange={e => updateReason("voltage")}
            />
          </Col>
        </FormGroup>
        <FormGroup row className="mb-1">
          <Col className="d-flex">
            <Button color="secondary" className="flex-grow-1" disabled={!reasonGivenIs("motor")} onClick={toggleMotorModal}>
              CodModeloMotor {dependent?.motor}
            </Button>
            <CustomInput
              type="switch"
              id={`rfc-toggle-motor-${test.id}`}
              disabled={isDisabled("motor")}
              checked={reasonGivenIs("motor")}
              onChange={e => updateReason("motor")}
            />
          </Col>
        </FormGroup>
        <FormGroup row className="mb-1">
          <Col className="d-flex">
            <Button color="secondary" className="flex-grow-1" disabled={!reasonGivenIs("curve")} onClick={toggleCurveModal}>
              CodModeloCurva {dependent?.curve}
            </Button>
            <CustomInput
              type="switch"
              id={`rfc-toggle-curve-${test.id}`}
              disabled={isDisabled("curve")}
              checked={reasonGivenIs("curve")}
              onChange={e => updateReason("curve")}
            />
          </Col>
        </FormGroup>
        <ListerTableSelectionModal
          title={intl.formatMessage({ id: "app.select_a_motor", defaultMessage: "Select a motor" })}
          columns={[
            { Header: intl.formatMessage({ id: "motors.cod_modelo_motor", defaultMessage: "CodModeloMotor" }), accessor: "id" },
            { Header: intl.formatMessage({ id: "motors.potencia", defaultMessage: "Power" }), accessor: "power" },
            { Header: intl.formatMessage({ id: "motors.frequency", defaultMessage: "Frequency" }), accessor: "frequency" },
            { Header: intl.formatMessage({ id: "motors.voltage", defaultMessage: "Voltage" }), accessor: "voltage" },
            { Header: intl.formatMessage({ id: "motors.poles", defaultMessage: "Poles" }), accessor: "poles" },
            { Header: intl.formatMessage({ id: "motors.frame_standard", defaultMessage: "Frame Std." }), accessor: "frameStandard" },
            { Header: intl.formatMessage({ id: "motors.efficiency_standard", defaultMessage: "Efficiency Std." }), accessor: "efficiencyStandard" },
            { Header: intl.formatMessage({ id: "motors.transmision", defaultMessage: "Transmision" }), accessor: "transmision" },
            { Header: intl.formatMessage({ id: "motors.flagPV_IEC", defaultMessage: "flagPV_IEC" }), accessor: "flagPV_IEC" },
            { Header: intl.formatMessage({ id: "motors.categoria", defaultMessage: "Categoria" }), accessor: "categoria" },
          ]}
          isOpen={motorModalOpen}
          toggle={toggleMotorModal}
          lister={motorLister}
          onSelect={handleMotorSelection}
        />
        <ListerTableSelectionModal
          title={intl.formatMessage({ id: "app.select_a_curve", defaultMessage: "Select a ModeloCurva" })}
          columns={[
            { Header: intl.formatMessage({ id: "app.curve", defaultMessage: "Curve" }), accessor: "name" },
            { Header: intl.formatMessage({ id: "app.curve_group", defaultMessage: "Curve group" }), accessor: "group" },
            { Header: intl.formatMessage({ id: "app.product", defaultMessage: "Product" }), accessor: "product" },
            { Header: intl.formatMessage({ id: "app.curve_series", defaultMessage: "Curve series" }), accessor: "curveSeries" }
          ]}
          isOpen={curveModalOpen}
          toggle={toggleCurveModal}
          lister={curveLister}
          onSelect={handleCurveSelection}
        />
      </>
    );
  }

  export default DependentCurveTestForm;