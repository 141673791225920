import React, { useState } from "react";
import ProductInputContainer from "../ProductInputContainer";
import { fireConfirm } from "../../../utils/alerts";
import { FormattedMessage, useIntl } from "react-intl";
import { getFormulaValues, lookupValueInAttributeOptionsById } from "../../../products/ProductDefinitions";
import ProductAttributes, { sectionTypes } from "../../../products/ProductAttributes";
import ProductInput from "../ProductInput";
import { Col, CustomInput, Label, Row } from "reactstrap";
import ProductInfo from "../ProductInfo";

function ElectricalParameters(props) {
  const intl = useIntl();
  const productAttributes = new ProductAttributes({
    object: props.product,
    attributes: props.productAttributes,
    update: props.update,
    updateAllProducts: props.updateAllProducts,
    intl,
  });
  return (
    <ProductInputContainer {...props} childrenIndex={5} inputs={productAttributes.getAttributes(sectionTypes.ELECTRICAL)} title="product_information.electrical_parameters">
      <SwitchableModes {...props}></SwitchableModes>
    </ProductInputContainer>
  );
}

function SwitchableModes({ isReadOnly, update, productAttributes, product, isWizard, disabled, checkValidation, validationErrorText }) {
  const [switchable, setSwitchable] = useState(false);

  function updateSwitchable(checked) {
    setSwitchable(checked);
    update({
      ...product,
      flagCommutador2: checked,
      flagCommutador3: false,
    });
  }

  function updateCommutador(v) {
    var isMode2 = v.target.id == "flagCommutador2" && v.target.checked;
    var isMode3 = v.target.id == "flagCommutador3" && v.target.checked;

    if (isMode2 == false && isMode3 == false) {
      if (v.target.id == "flagCommutador2") isMode3 = true;
      if (v.target.id == "flagCommutador3") isMode2 = true;
    }

    update({
      ...product,
      flagCommutador2: isMode2,
      flagCommutador3: isMode3,
    });
    //
  }

  return (
    <Col sm={12} xl={6}>
      <Row>
        <Label sm={6} xl={6}>
          <FormattedMessage id="attribute.flagSwitchable" defaultMessage="Switchable" />
          <ProductInfo formattedMessage={{ id: "product_information.switchable_help", defaultMessage: "product_information.switchable_help" }}></ProductInfo>
        </Label>
        <Col sm={6} xl={6}>
          <div className="d-flex">
            <CustomInput
              type="switch"
              id="flagSwitchable"
              checked={switchable}
              onChange={(e) => {
                updateSwitchable(e.target.checked);
              }}
              disabled={isReadOnly}
            />
          </div>
        </Col>
      </Row>
      <Row>
        <ProductInput
          attributeClass={"flagCommutador2"}
          attributes={productAttributes}
          obj={product}
          update={updateCommutador}
          labelProps={{ sm: { size: 5, offset: 1 } }}
          columnProps={{ sm: { size: 6 }, xl: { size: 6 } }}
          isWizard={isWizard}
          disabled={!switchable}
          className={checkValidation ? checkValidation("flagCommutador2") : undefined}
          tooltipText={validationErrorText ? validationErrorText("flagCommutador2") : undefined}
        ></ProductInput>
      </Row>
      <Row>
        <ProductInput
          attributeClass={"flagCommutador3"}
          attributes={productAttributes}
          obj={product}
          update={updateCommutador}
          labelProps={{ sm: { size: 5, offset: 1 } }}
          columnProps={{ sm: { size: 6 }, xl: { size: 6 } }}
          isWizard={isWizard}
          disabled={!switchable}
          className={checkValidation ? checkValidation("flagCommutador3") : undefined}
          tooltipText={validationErrorText ? validationErrorText("flagCommutador3") : undefined}
        ></ProductInput>
      </Row>
    </Col>
  );
}

export default ElectricalParameters;
