import React, { useState } from "react";
import { Container, Row, Col, Card, CardBody, Button, CardFooter } from "reactstrap";
import Header from "../../components/Header";
import { FormattedMessage, useIntl } from "react-intl";
import SeriesLoadingsQueue from "../../components/SeriesLoadings/SeriesLoadingsQueue";
import SeriesLoadingsResults from "../../components/SeriesLoadings/SeriesLoadingsResults";
import { useService } from "../../context/ServiceContext";
import { fireAlert } from "../../utils/alerts";
import SeriesLoadingStatus from "../../components/SeriesLoadings/SeriesLoadingStatus";

function SeriesLoadings({ history }) {
  const { seriesLoadingProcessor, etimLegacyProcessor } = useService();
  const intl = useIntl();

  async function handleSeriesLoadingRequest(seriesId) {
    await seriesLoadingProcessor.store({ seriesId: seriesId, processId: 0 });
    showAlert();
  }

  async function handleSeriesLoadingQueueRequest(seriesId, processId) {
    await seriesLoadingProcessor.store({ seriesId: seriesId, processId: processId });
    showAlert();
  }

  const [curvesProcessing, setCurvesProcessing] = useState(false);

  async function handleETIMLegacyProcess() {
    try {
      setCurvesProcessing(true);
      await etimLegacyProcessor.find();
      showAlert();
    } catch (e) {
      showErrorAlert();
    }
    setCurvesProcessing(false);
  }

  function showErrorAlert() {
    fireAlert(
      intl.formatMessage({ id: "manage_loadings.unsuccessful_set_request", defaultMessage: "A problem occurred sending the request" }),
      intl.formatMessage({ id: "app.error", defaultMessage: "Error" }),
      "error"
    ).then(() => {});
  }

  function showAlert() {
    fireAlert(
      intl.formatMessage({ id: "manage_loadings.successfully_set_request", defaultMessage: "Successfully set request" }),
      intl.formatMessage({ id: "app.success", defaultMessage: "Success" }),
      "success"
    ).then(() => {});
  }

  return (
    <>
      <Header>
        <h1>
          <FormattedMessage id="manage_loadings.title" defaultMessage="Manage loadings" />
        </h1>
      </Header>
      <Container fluid>
        <Row>
          <Col md={5}>
            <SeriesLoadingsResults onRequestClick={handleSeriesLoadingRequest}></SeriesLoadingsResults>
          </Col>
          <Col md={7}>
            <SeriesLoadingsQueue onRequestClick={handleSeriesLoadingQueueRequest}></SeriesLoadingsQueue>
          </Col>
        </Row>
        <Row>
          <Col>
            <SeriesLoadingStatus></SeriesLoadingStatus>
          </Col>
        </Row>
        <Row>
          <Col className="mt-3"></Col>
        </Row>
        <Row>
          <Col md={6}>
            <Card>
              <CardBody>
                <Row>
                  <Col>
                    <h3 className="card-title">
                      <FormattedMessage id="admin_actions.curves_loadings.title" defaultMessage="Curves Loadings" />
                    </h3>
                    <p className="card-text">
                      <FormattedMessage id="admin_actions.curves_loadings.description" defaultMessage="Click the button below if you wish to start the curve loadings process." />
                    </p>
                  </Col>
                </Row>
              </CardBody>
              <CardFooter>
                <Button disabled={curvesProcessing} onClick={handleETIMLegacyProcess} color="primary">
                  {curvesProcessing ? <FormattedMessage id="app.processing" defaultMessage="Processing" /> : <FormattedMessage id="app.proceed" defaultMessage="Proceed" />}
                </Button>
              </CardFooter>
            </Card>
          </Col>
        </Row>
        <Row>
          <Col className="mt-3"></Col>
        </Row>
      </Container>
    </>
  );
}

export default SeriesLoadings;
