import React, { useEffect, useReducer, useRef, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { Route, Switch, NavLink as NavLinkRRD, Prompt } from "react-router-dom";
import { Container, Row, Col, Card, CardBody, Nav, NavItem, NavLink, CustomInput } from "reactstrap";
import Curves from "../../components/CurvesGenerator/Curves";
import CurveViewerHeader from "../../components/CurvesGenerator/CurveViewerHeader";
import DataEntry from "../../components/CurvesGenerator/DataEntry";
import ErPCompliance from "../../components/CurvesGenerator/ErPCompliance";
import Summary from "../../components/CurvesGenerator/Summary";
import ErrorAlertContent from "../../components/ErrorAlertContent";
import Header from "../../components/Header";
import InlineError from "../../components/InlineError";
import LoadedContent from "../../components/LoadedContent";
import ListerTableSelectionModal from "../../components/Modals/ListerTableSelectionModal";
import { useConfig } from "../../context/ConfigContext";
import { useService } from "../../context/ServiceContext";
import CurveTest from "../../curves/CurveTest";
import { fireAlert } from "../../utils/alerts";
import { useSessionStorage } from "../../utils/useSessionStorage";
import clonedeep from "lodash.clonedeep";
import RelatedErPKitsModal from "../../components/CurvesGenerator/RelatedErPKitsModal";
import { isEmpty } from "lodash";
import  Formatter  from "../../utils/Formatter";

function CurveViewerContent(props) {
  const intl = useIntl();
  const {customFormatMessage} = Formatter();
  const { onChangeState, onChangeChanges, onReset, onSave } = props;
  const config = useConfig("genesis.gdec");
  const curveTest = useRef();
  const laboratoryTestReference = props.state.laboratoryTest.reference;
  const laboratoryTestCreatedAt = new Date(props.state.laboratoryTest.createdAt);
  const laboratoryTestDescription = props.state.laboratoryTest.description;
  const laboratoryTestVoltage = props.state.laboratoryTest.voltage;
  const laboratoryTestData = props.state.points;
  const curveSeries = props.state.curve.series;
  const curveGroup = props.state.curve.group?.name;
  const curveName = props.state.curve.name;
  const impellerDiameter = props.state.product?.impellerDiameter;
  const section = props.state.product.section;
  const relatedErPKits = props.state.relatedErPKits;
  const hasRelatedErPKits = !!relatedErPKits && relatedErPKits.length !== 0;
  const updatedAt = new Date(props.state.updatedAt);
  const readOnly = props.state.isReadOnly;
  const [ready, setReady] = useState(false);
  const [interpolatedData, setInterpolatedData] = useState(props.state.interpolation?.points);
  const [isInterpolatedDataModified, setIsInterpolatedDataModified] = useState(props.state.interpolation?.isInterpolatedPointsModified);
  const [selectionData, setSelectionData] = useState(props.state.selection?.points);
  const [selectionMinimumLimit, dispatchSelectionMinimumLimit] = useReducer(limitReducer("min"));
  const [selectionMaximumLimit, dispatchSelectionMaximumLimit] = useReducer(limitReducer("max"));
  const [openedCurveTab, setOpenedCurveTab] = useState("test");
  const [interpolationCoefficient, setInterpolationCoefficient] = useState(props.state.interpolation?.settings?.coefficient);
  const [autoInterpolation, setAutoInterpolation] = useState(false);
  const [isSelectionInterpolationDisabled, setIsSelectionInterpolationDisabled] = useState(props.state.selection?.settings?.isInterpolationDisabled);
  const [isPublished, setIsPublished] = useState(props.state.isPublished);
  const [hasErP, setHasErP] = useState(props.state.hasErP);
  const [isErPNorm, setIsErPNorm] = useState(props.state.isErPNorm);
  const [isErPTest, setIsErPTest] = useState(props.state.isErPTest);
  const [isTransmissionEnabled, setIsTransmissionEnabled] = useState(props.state.transmission?.isEnabled);
  const [transmissionNormalisation, setTransmissionNormalisation] = useState(props.state.transmission?.normalisation);
  const [efficiencyType, setEfficiencyType] = useState(props.state.product.settings.efficiencyType);
  const [category, setCategory] = useState(props.state.product.settings.category);
  const [installationType, setInstallationType] = useState(props.state.product.settings.installationType);
  const [vsdType, setVsdType] = useState(props.state.product.settings.vsdType);
  const [includeSecurityGuard, setIncludeSecurityGuard] = useState(props.state.product.settings.guardFactor != 0 && props.state.product.settings.guardFactor != null);
  const [guardFactor, setGuardFactor] = useState(props.state.product.settings.guardFactor);
  const [vsd, setVsd] = useState(props.state.product.settings.isVsdIncluded);
  const [isSmokeExtraction, setIsSmokeExtraction] = useState(props.state.product.settings.isSmokeExtraction);
  const [isReversible, setIsReversible] = useState(props.state.product.settings.isReversible);
  const [isCatalogue, setIsCatalogue] = useState(props.state.isCatalogue);
  const [isPrincipalErP, setIsPrincipalErP] = useState(props.state.isPrincipalErP);
  const [isOutputPowerCalculated, setIsOutputPowerCalculated] = useState(props.state.isOutputPowerCalculated);
  const [motor, setMotor] = useState(props.state.motor);
  const [outletDiameter, setOutletDiameter] = useState(props.state.product.settings.outletDiameter);
  const [inletDiameter, setInletDiameter] = useState(props.state.product.settings.inletDiameter);
  const [newSection, setNewSection] = useState(props.state.product.settings.newSection);
  const [observations, setObservations] = useState(props.state.observations ?? "");
  const [erPCompliance, setErPCompliance] = useState(props.state.erPCompliance);
  const [thrust, setThrust] = useState(props.state.product.settings.thrust);
  const [electricPower, setElectricPower] = useState(props.state.product.settings.electricPower);
  const [outletArea, setOuletArea] = useState(props.state.product.settings.outletArea);
  const [motorModalOpen, setMotorModalOpen] = useState(false);
  const [relatedErPKitSelectionOpen, setKitSelectionOpen] = useState(false);
  const toggleMotorModal = () => setMotorModalOpen(!motorModalOpen);
  const toggleRelatedErPKitSelectionOpen = () => setKitSelectionOpen(!relatedErPKitSelectionOpen);
  const { motorLister, motorProvider, curveTestService } = useService();
  const [isGenericsOnly, setIsGenericsOnly] = useState(true);

  useEffect(() => {
    curveTest.current = new CurveTest({ state: props.state, changes: props.changes, config });

    if (curveTest.current.interpolation.settings.coefficient === null) {
      curveTest.current.interpolation.settings.coefficient = 1;
      setInterpolationCoefficient(curveTest.current.interpolation.settings.coefficient);
    }

    dispatchSelectionLimits();

    updateCalculatedData();

    if (!erPCompliance || curveTest.current.erPCompliance.targets?.findIndex((e) => e.name == "V2026") == -1 ) {
      recalculateErPCompliance(curveTest.current.changes, true).then(() => {
        dispatchUpdates();
        setReady(true);
      });
    } else {
      dispatchUpdates();
      setReady(true);
    }
  }, []);

  async function handleMotorSelection(choice) {
    if (choice) {
      let spec = await motorProvider.get(choice.id);
      setMotor(spec);
      curveTest.current.motor = spec;
      await recalculateErPCompliance(curveTest.current.changes);
    }
    toggleMotorModal();
    dispatchUpdates();
  }

  const handleRelatedErPKitSelection = (kitErP) => {
    props.history.push(`/curves-generator/erp/viewer/${kitErP.isResidential ? "rvu" : "nrvu"}/${encodeURIComponent(kitErP.name)}`);
  };

  async function handleRemoveMotor() {
    setMotor(undefined);
    curveTest.current.motor = null;
    await recalculateErPCompliance(curveTest.current.changes);
    dispatchUpdates();
  }

  function dispatchSelectionLimits() {
    if (curveTest.current.selection.settings.min.airflow === null) {
      dispatchSelectionMinimumLimit({
        variable: "airflow",
        value: Math.min.apply(
          Math,
          curveTest.current.interpolation.points.map((p) => p.airflow)
        ),
      });
    } else {
      dispatchSelectionMinimumLimit({ variable: "airflow", value: curveTest.current.selection.settings.min.airflow });
    }

    if (curveTest.current.selection.settings.max.airflow === null) {
      dispatchSelectionMaximumLimit({
        variable: "airflow",
        value: Math.max.apply(
          Math,
          curveTest.current.interpolation.points.map((p) => p.airflow)
        ),
      });
    } else {
      dispatchSelectionMaximumLimit({ variable: "airflow", value: curveTest.current.selection.settings.max.airflow });
    }
  }

  function dispatchUpdates() {
    if (ready) {
      onChangeState && onChangeState(curveTest.current.state);
      onChangeChanges && onChangeChanges(curveTest.current.changes);
    }
  }

  async function handleCalculateInterpolatedCurve() {
    if (autoInterpolation) {
      curveTest.current.interpolation.settings.useAutomaticCoefficient();
    } else {
      curveTest.current.interpolation.settings.coefficient = interpolationCoefficient;
    }

    dispatchSelectionLimits();
    updateCalculatedData();
    await recalculateErPCompliance(curveTest.current.changes);
    dispatchUpdates();
  }

  function limitReducer(name) {
    if (!curveTest.current) {
      return;
    }

    let limit = null;

    if (name === "min") {
      limit = curveTest.current.selection.settings.min;
    } else if (name === "max") {
      limit = curveTest.current.selection.settings.max;
    }

    return (_, action) => {
      switch (action.variable) {
        case "airflow":
          limit.airflow = action.value;
          break;

        case "staticPressure":
          limit.staticPressure = action.value;
          break;

        case "efficiencyPercentage":
          limit.efficiencyPercentage = action.value;
          break;
      }

      return limit.state;
    };
  }

  function handleChangeSelectionLimit(dispatcher) {
    return (_, action) => {
      dispatcher(_, action);

      setSelectionData(curveTest.current.selection.points);
      recalculateErPCompliance(curveTest.current.changes).then(dispatchUpdates);
    };
  }

  async function handleChangeIsSelectionInterpolationDisabled(value) {
    setIsSelectionInterpolationDisabled(value);
    curveTest.current.selection.settings.isInterpolationDisabled = value;
    dispatchSelectionLimits();
    setSelectionData(curveTest.current.selection.points);
    await recalculateErPCompliance(curveTest.current.changes);
    dispatchUpdates();
  }

  function handleChangeIsPublished(value) {
    setIsPublished(value);
    curveTest.current.isPublished = value;
    dispatchUpdates();
  }

  function handleChangeHasErP(value) {
    setHasErP(value);
    curveTest.current.hasErP = value;
    dispatchUpdates();
  }

  function handleChangeIsErPNorm(value) {
    setIsErPNorm(value);
    curveTest.current.isErPNorm = value;
    dispatchUpdates();
  }

  function handleChangeIsErPTest(value) {
    setIsErPTest(value);
    curveTest.current.isErPTest = value;
    dispatchUpdates();
  }

  function handleChangeIsTransmissionEnabled(value) {
    setIsTransmissionEnabled(value);
    curveTest.current.transmission.isEnabled = value;
    dispatchUpdates();
  }

  function handleChangeTransmissionNormalisation(value) {
    setTransmissionNormalisation(value);
    curveTest.current.transmission.normalisation = value;
    dispatchUpdates();
  }

  async function handleChangeEfficiencyType(value) {
    setEfficiencyType(value);
    curveTest.current.product.settings.efficiencyType = value;

    await recalculateErPCompliance(curveTest.current.changes);
    dispatchUpdates();
  }

  async function handleChangeCategory(value) {
    let prevCategory = category;
    setCategory(value);
    curveTest.current.product.settings.category = value;
    if (prevCategory == "G16" && value != "G16") {
      if (isEmpty(installationType)) {
        setInstallationType("A");
        curveTest.current.product.settings.installationType = "A";
      }
      if (isEmpty(efficiencyType)) {
        setEfficiencyType("Static");
        curveTest.current.product.settings.efficiencyType = "Static";
      }
    }
    await recalculateErPCompliance(curveTest.current.changes);
    dispatchUpdates();
  }

  async function handleChangeInstallationType(value) {
    setInstallationType(value);
    curveTest.current.product.settings.installationType = value;

    await recalculateErPCompliance(curveTest.current.changes);
    dispatchUpdates();
  }

  async function handleChangeVsdType(value) {
    setVsdType(value);
    curveTest.current.product.settings.vsdType = value;
    setVsd(curveTest.current.product.settings.isVsdIncluded);

    await recalculateErPCompliance(curveTest.current.changes);
    dispatchUpdates();
  }

  async function handleChangeIncludeSecurityGuard(value) {
    setIncludeSecurityGuard(value);
    //curveTest.current.product.settings.includeSecurityGuard = value;
    if (value && (guardFactor == null || guardFactor == 0)) {
      value = 1.15;
    }
    if (value == false) {
      value = 0;
    }
    var guardFactor = value == handleChangeGuardFactor(value);

    // await recalculateErPCompliance(curveTest.current.changes);
    // dispatchUpdates();
  }

  async function handleChangeGuardFactor(value) {
    setGuardFactor(value);
    curveTest.current.product.settings.guardFactor = value;
    //handleChangeIncludeSecurityGuard(curveTest.current.product.settings.includeSecurityGuard);
    await recalculateErPCompliance(curveTest.current.changes);
    dispatchUpdates();
  }

  async function handleChangeIsSmokeExtraction(value) {
    setIsSmokeExtraction(value);
    curveTest.current.product.settings.isSmokeExtraction = value;

    await recalculateErPCompliance(curveTest.current.changes);
    dispatchUpdates();
  }
  async function handleChangeIsReversible(value) {
    setIsReversible(value);
    curveTest.current.product.settings.isReversible = value;

    await recalculateErPCompliance(curveTest.current.changes);
    dispatchUpdates();
  }

  function handleChangeIsCatalogue(value) {
    setIsCatalogue(value);
    curveTest.current.isCatalogue = value;
    dispatchUpdates();
  }

  function handleChangeIsPrincipalErP(value) {
    setIsPrincipalErP(value);
    curveTest.current.isPrincipalErP = value;
    dispatchUpdates();
  }

  function handleChangeIsOutputPowerCalculated(value) {
    setIsOutputPowerCalculated(value);
    curveTest.current.isOutputPowerCalculated = value;
    dispatchUpdates();
  }

  async function handleChangeOutletDiameter(value) {
    setOutletDiameter(value);
    curveTest.current.product.settings.outletDiameter = value;
    setNewSection(curveTest.current.product.settings.newSection);

    await recalculateErPCompliance(curveTest.current.changes);
    dispatchUpdates();
  }
  async function handleChangeInletDiameter(value) {
    setInletDiameter(parseFloat(value));
    curveTest.current.product.settings.inletDiameter = parseFloat(value);
    //setNewSection(curveTest.current.product.settings.newSection);
    curveTest.current.interpolation.changeDynamicPressure(
      curveTest.current.product.settings.newInletArea > 0 ? curveTest.current.product.settings.newInletArea : curveTest.current.product.section
    );
    updateCalculatedData();
    await recalculateErPCompliance(curveTest.current.changes);
    dispatchUpdates();
  }
  async function handleChangeThrust(value) {
    setThrust(parseFloat(value));
    curveTest.current.product.settings.thrust = parseFloat(value);
    await recalculateErPCompliance(curveTest.current.changes);
    dispatchUpdates();
  }
  async function handleChangeElectricPower(value) {
    setElectricPower(parseFloat(value));
    curveTest.current.product.settings.electricPower = parseFloat(value);
    await recalculateErPCompliance(curveTest.current.changes);
    dispatchUpdates();
  }
  async function handleChangeOutletArea(value) {
    setOuletArea(parseFloat(value));
    curveTest.current.product.settings.outletArea = parseFloat(value);
    await recalculateErPCompliance(curveTest.current.changes);
    dispatchUpdates();
  }

  function handleChangeObservations(value) {
    setObservations(value);
    curveTest.current.observations = value;
    dispatchUpdates();
  }

  async function recalculateErPCompliance(inputs, initial) {
    if (ready || initial) {
      setErPCompliance(null);
      try {
        setErPCompliance((curveTest.current.erPCompliance = await curveTestService.evaluateErPCompliance({ id: props.id, inputs })));
      } catch (e) {
        setErPCompliance(null);
      }
    }
  }

  function updateCalculatedData() {
    setInterpolatedData(curveTest.current.interpolation.points);
    setIsInterpolatedDataModified(curveTest.current.interpolation.isInterpolatedPointsModified);
    setSelectionData(curveTest.current.selection.points);
  }

  function handleChangeInterpolatedDataValue(index, variable, value) {
    curveTest.current.interpolation.set(index, variable, value);
    updateCalculatedData();
    dispatchUpdates();
  }

  function handleInsertInterpolatedDataRow(index) {
    curveTest.current.interpolation.insert(index);
    updateCalculatedData();
    dispatchUpdates();
  }

  function handleRemoveInterpolatedDataRow(index) {
    curveTest.current.interpolation.remove(index);
    updateCalculatedData();
    dispatchUpdates();
  }

  async function handleSave() {
    const persistentWarnings = erPCompliance?.warnings != null && erPCompliance.warnings.filter((w) => w.persistOnSave);

    if (persistentWarnings?.length > 0) {
      fireAlert(
        <ErrorAlertContent
          message={persistentWarnings.map((w) => intl.formatMessage({ id: w.id, defaultMessage: customFormatMessage(w.defaultMessage, w.values) })).join(" \n ")}
        />,
        intl.formatMessage({ id: "app.warning", defaultMessage: "Warning" }),
        "warning"
      );

      return;
    }

    onSave && (await onSave());
  }

  function handleReset() {
    if (onReset) {
      onReset();
      curveTest.current = new CurveTest({ state: { ...props.state }, changes: { ...props.changes }, config });
    }
  }

  function handleSwitchChange(e) {
    setIsGenericsOnly(e.target.checked);
  }

  function inject(render) {
    return (props) => {
      const newProps = {
        ...props,
        laboratoryTestReference,
        laboratoryTestCreatedAt,
        laboratoryTestDescription,
        laboratoryTestVoltage,
        laboratoryTestData,
        impellerDiameter,
        section,
        updatedAt,
        interpolatedData,
        isInterpolatedDataModified,
        selectionData,
        curveSeries,
        curveGroup,
        curveName,
        interpolationCoefficient,
        onChangeInterpolationCoefficient: setInterpolationCoefficient,
        autoInterpolation,
        onChangeAutoInterpolation: setAutoInterpolation,
        isSelectionInterpolationDisabled,
        onChangeIsSelectionInterpolationDisabled: handleChangeIsSelectionInterpolationDisabled,
        isPublished,
        onChangeIsPublished: handleChangeIsPublished,
        hasErP,
        onChangeHasErP: handleChangeHasErP,
        isErPNorm,
        onChangeIsErPNorm: handleChangeIsErPNorm,
        isErPTest,
        onChangeIsErPTest: handleChangeIsErPTest,
        isTransmissionEnabled,
        onChangeIsTransmissionEnabled: handleChangeIsTransmissionEnabled,
        transmissionNormalisation,
        onChangeTransmissionNormalisation: handleChangeTransmissionNormalisation,
        efficiencyType,
        onChangeEfficiencyType: handleChangeEfficiencyType,
        category,
        onChangeCategory: handleChangeCategory,
        installationType,
        onChangeInstallationType: handleChangeInstallationType,
        vsdType,
        onChangeVsdType: handleChangeVsdType,
        includeSecurityGuard,
        onChangeIncludeSecurityGuard: handleChangeIncludeSecurityGuard,
        guardFactor,
        onChangeGuardFactor: handleChangeGuardFactor,
        vsd,
        isSmokeExtraction,
        onChangeIsSmokeExtraction: handleChangeIsSmokeExtraction,
        isReversible,
        onChangeIsReversible: handleChangeIsReversible,
        isCatalogue,
        onChangeIsCatalogue: handleChangeIsCatalogue,
        isPrincipalErP,
        onChangeIsPrincipalErP: handleChangeIsPrincipalErP,
        isOutputPowerCalculated,
        onChangeIsOutputPowerCalculated: handleChangeIsOutputPowerCalculated,
        motor,
        onBeginMotorSelection: toggleMotorModal,
        onRemoveMotor: handleRemoveMotor,
        onOpenRelatedErPKitSelection: toggleRelatedErPKitSelectionOpen,
        outletDiameter,
        onChangeOutletDiameter: handleChangeOutletDiameter,
        inletDiameter,
        onChangeInletDiameter: handleChangeInletDiameter,
        newSection,
        observations,
        onChangeObservations: handleChangeObservations,
        erPCompliance,
        hasRelatedErPKits,
        relatedErPKits,
        openedCurveTab,
        onOpenCurveTab: setOpenedCurveTab,
        onRecalculateInterpolatedCurve: handleCalculateInterpolatedCurve,
        onChangeInterpolatedDataValue: handleChangeInterpolatedDataValue,
        onInsertInterpolatedDataRow: handleInsertInterpolatedDataRow,
        onRemoveInterpolatedDataRow: handleRemoveInterpolatedDataRow,
        selectionMinimumLimit,
        onChangeSelectionMinimumLimit: handleChangeSelectionLimit(dispatchSelectionMinimumLimit),
        selectionMaximumLimit,
        onChangeSelectionMaximumLimit: handleChangeSelectionLimit(dispatchSelectionMaximumLimit),
        onSave: handleSave,
        onReset: handleReset,
        readOnly,
        thrust,
        onChangeThrust: handleChangeThrust,
        electricPower,
        onChangeElectricPower: handleChangeElectricPower,
        outletArea,
        onChangeOutletArea: handleChangeOutletArea,
      };

      return render(newProps);
    };
  }

  function isTabActive(pathname, tab) {
    return pathname?.endsWith(`/${tab}`) ?? false;
  }

  function showErPCompliance() {
    return !!curveTest.current?.erPCompliance;
  }

  return (
    <>
      <Row>
        <Col>
          <Card className="mb-3">
            <CardBody>{inject((props) => <CurveViewerHeader {...props} />)(props)}</CardBody>
          </Card>
        </Col>
      </Row>
      <Row>
        <Col>
          <Card>
            <CardBody className="px-0 pb-0">
              <Nav tabs className="align-items-center px-3">
                {!curveTest.current?.isNew ? (
                  <NavItem>
                    <NavLink to="summary" activeClassName="active" isActive={(_, match) => isTabActive(match.pathname, "summary")} tag={NavLinkRRD}>
                      <FormattedMessage id="curve_viewer.summary" defaultMessage="Summary" />
                    </NavLink>
                  </NavItem>
                ) : (
                  <></>
                )}
                <NavItem>
                  <NavLink to="data" activeClassName="active" isActive={(_, match) => isTabActive(match.pathname, "data")} tag={NavLinkRRD}>
                    <FormattedMessage id="curve_viewer.data" defaultMessage="Data entry" />
                  </NavLink>
                </NavItem>
                {category != "G16" && (
                  <NavItem>
                    <NavLink to="curves" activeClassName="active" isActive={(_, match) => isTabActive(match.pathname, "curves")} tag={NavLinkRRD}>
                      <FormattedMessage id="curve_viewer.curves" defaultMessage="Curves" />
                    </NavLink>
                  </NavItem>
                )}

                {showErPCompliance() && (
                  <NavItem>
                    <NavLink to="erp" activeClassName="active" isActive={(_, match) => isTabActive(match.pathname, "erp")} tag={NavLinkRRD}>
                      <FormattedMessage id="curve_viewer.erp_compliance" defaultMessage="ErP compliance" />
                    </NavLink>
                  </NavItem>
                )}
              </Nav>

              <div>
                <Switch>
                  <Route exact={true} path="(.*?)/data" render={() => inject((props) => <DataEntry {...props} />)(props)} />
                  <Route exact={true} path="(.*?)/summary" render={() => inject((props) => <Summary {...props} />)(props)} />
                  <Route exact={true} path="(.*?)/curves" render={() => inject((props) => <Curves {...props} />)(props)} />
                  <Route exact={true} path="(.*?)/erp" render={() => inject((props) => <ErPCompliance {...props} />)(props)} />
                </Switch>
              </div>
            </CardBody>
          </Card>
        </Col>
      </Row>

      <ListerTableSelectionModal
        title={intl.formatMessage({ id: "app.select_a_motor", defaultMessage: "Select a motor" })}
        filterSwitch={() => (
          <>
            <Col style={{ marginBottom: "-24px", marginTop: "15px" }}>
              <CustomInput
                type="switch"
                label={intl.formatMessage({ id: "motors.genericsonly", defaultMessage: "Generics only" })}
                checked={isGenericsOnly}
                onChange={(e) => handleSwitchChange(e)}
                id="motors.genericsonly"
              />
            </Col>
          </>
        )}
        columns={[
          { Header: intl.formatMessage({ id: "motors.cod_modelo_motor", defaultMessage: "CodModeloMotor" }), accessor: "id" },
          { Header: intl.formatMessage({ id: "motors.potencia", defaultMessage: "Power" }), accessor: "power" },
          { Header: intl.formatMessage({ id: "motors.frequency", defaultMessage: "Frequency" }), accessor: "frequency" },
          { Header: intl.formatMessage({ id: "motors.voltage", defaultMessage: "Voltage" }), accessor: "voltage" },
          { Header: intl.formatMessage({ id: "motors.poles", defaultMessage: "Poles" }), accessor: "poles" },
          { Header: intl.formatMessage({ id: "motors.frame_standard", defaultMessage: "Frame Std." }), accessor: "frameStandard" },
          { Header: intl.formatMessage({ id: "motors.efficiency_standard", defaultMessage: "Efficiency Std." }), accessor: "efficiencyStandard" },
          { Header: intl.formatMessage({ id: "motors.transmision", defaultMessage: "Transmision" }), accessor: "transmision" },
          { Header: intl.formatMessage({ id: "motors.flagPV_IEC", defaultMessage: "flagPV_IEC" }), accessor: "flagPV_IEC" },
          { Header: intl.formatMessage({ id: "motors.categoria", defaultMessage: "Categoria" }), accessor: "categoria" },
          { Header: intl.formatMessage({ id: "motors.brand_description", defaultMessage: "Marca" }), accessor: "marcaDescripcion" },
        ]}
        prefilter={{ isgenericsonly: isGenericsOnly }}
        isOpen={motorModalOpen}
        toggle={toggleMotorModal}
        lister={motorLister}
        onSelect={handleMotorSelection}
      />
      {hasRelatedErPKits &&
        inject((props) => <RelatedErPKitsModal isOpen={relatedErPKitSelectionOpen} toggle={toggleRelatedErPKitSelectionOpen} onSelect={handleRelatedErPKitSelection} {...props} />)(
          props
        )}
    </>
  );
}

function CurveViewer(props) {
  const { specId, labTestId } = props.match.params;
  const isNew = props.match.params.id === "new";
  const intl = useIntl();
  const sessionKeyRoot = `Genesis.Curve.${props.match.params.id}`;
  const [state, setState, invalidateState] = useSessionStorage(`${sessionKeyRoot}.state`, null);
  const [changes, setChanges, invalidateChanges] = useSessionStorage(`${sessionKeyRoot}.changes`, {});
  const [original, setOriginal, invalidateOriginal] = useSessionStorage(`${sessionKeyRoot}.original`, {});
  const [resets, setResets] = useState(0);
  const [id, setId] = useState(null);
  const [error, setError] = useState(null);
  const [isSaving, setIsSaving] = useState(false);
  const [isSaved, setIsSaved] = useState(!isNew && Object.entries(changes).length === 0);
  const toRetry = useRef(null);
  const { curveTestService } = useService();

  async function retry() {
    if (!toRetry.current) {
      return;
    }

    try {
      if (toRetry.current.constructor.name === "AsyncFunction") {
        await toRetry.current();
      } else {
        toRetry.current();
      }

      setError(null);
    } catch (e) {
      setError(e);
    }
  }

  useEffect(() => {
    const loadState = () => {
      let promise = null;

      if (isNew) {
        promise = curveTestService.originateCurveTest({ curveId: specId, testId: labTestId });
      } else {
        promise = curveTestService.getCurveTest(props.match.params.id);
      }

      if (promise) {
        promise
          .then((result) => {
            setId(result.id);
            setState(result);
            setOriginal(result);
          })
          .catch((e) => {
            toRetry.current = loadState;
            setError(e);
          });
      }
    };

    if (state) {
      setId(state.id);
    } else {
      loadState();
    }

    // clean-up
    return () => {
      invalidate();
    };
  }, []);

  function isLoading() {
    return state === null || (state.id && !id);
  }

  function handleChangeState(state) {
    setState(state);
  }

  function handleChangeChanges(changes) {
    setChanges(changes);
    setIsSaved(false);
  }

  async function handleSave() {
    try {
      let newId = null;
      setIsSaving(true);

      if (isNew) {
        newId = await curveTestService.createCurveTest({
          inputs: {
            ...changes,
            origin: {
              curveId: specId,
              testId: {
                value: labTestId,
                version: 0,
              },
            },
          },
        });
      } else {
        newId = await curveTestService.updateCurveTest({ id, inputs: changes });
      }

      setId(newId);
      setState({ ...state, id: newId });
      setIsSaved(true);
      setChanges({});

      if (isNew) {
        props.history.push(`/curves-generator/viewer/${newId.value}/summary`);
      }
    } catch (e) {
      if (e.localization.id === "curves_generator.curve_test_not_found") {
        invalidate();
        fireAlert(
          <ErrorAlertContent
            message={intl.formatMessage({
              id: "curves_generator.curve_test_was_modified",
              defaultMessage: "The idEnsayo was modified while you were editing it. Please reload.",
            })}
            traceId={e.traceId}
          />,
          intl.formatMessage({ id: "app.error", defaultMessage: "Error" }),
          "error"
        );
      } else {
        toRetry.current = handleSave;
        setError(e);
      }

      throw e;
    } finally {
      setIsSaving(false);
    }
  }

  function invalidate() {
    invalidateState();
    invalidateChanges();
    invalidateOriginal();
  }

  function handleReset() {
    setResets(resets + 1);
    setChanges({});
    setIsSaved(true);
    setState(clonedeep(original));
  }

  function handleNavigation(params) {
    return (
      isSaved ||
      params.pathname.includes("/curves-generator/viewer/") ||
      intl.formatMessage({
        id: "app.unsavedchanges",
        defaultMessage: "Are you sure you want to continue? You may lose any unsaved changes.",
      })
    );
  }

  return (
    <>
      <Prompt message={handleNavigation} />

      <Header>
        <h1>
          <FormattedMessage id="curves_generator.title" defaultMessage="Curves Generator" />
        </h1>
      </Header>
      <Container fluid>
        {!error ? (
          <LoadedContent loading={isLoading() || isSaving} overlay={isSaving}>
            <CurveViewerContent
              {...props}
              id={id}
              state={{ ...state }}
              changes={{ ...changes }}
              onChangeState={handleChangeState}
              onChangeChanges={handleChangeChanges}
              onReset={handleReset}
              isSaving={isSaving}
              isSaved={isSaved}
              onSave={handleSave}
              key={resets}
            />
          </LoadedContent>
        ) : (
          <InlineError message={intl.formatMessage(error.localization, error.localization.values)} traceId={error.traceId} onRetry={retry} />
        )}
      </Container>
    </>
  );
}

export default CurveViewer;
